import { HttpClient } from '@angular/common/http';
import { Injectable, signal, inject, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { environment } from '@environment';
import {
  UserInfo,
  ConfigValue,
  QuestionState,
  GameState,
  UserAction,
} from '@longnecktech/splash-commons-fe';
import { UserActivityService } from '@services/user-activity.service';
import { Theme } from '@shared/types/theme';
import { Game } from '@shared/types/game';
import { InfoContentDialogType } from '@shared/types/info-content-dialog-type';
import {
  BehaviorSubject,
  map,
  Observable,
  interval,
  switchMap,
  filter,
  catchError,
  mergeMap,
  of,
} from 'rxjs';
import { LocalStorageService } from '@services/local-storage.service';
import { GAME_STATE } from '@shared/constants/local-storage-keys.consts';
import {
  Question,
  QuestionType,
  Transaction,
} from '@longnecktech/splash-commons-fe';
import { UserActionService } from './user-action.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private _isMobile = new BehaviorSubject<boolean | null>(null);
  private _hasResults = new BehaviorSubject<boolean>(false);
  private _hasCurrentGameResults = new BehaviorSubject<boolean>(false);
  private _isOpenedInfoContentDialog = new BehaviorSubject<boolean>(false);
  private _theme = new BehaviorSubject<Theme | undefined>(undefined);
  private _infoContentDialogType = new BehaviorSubject<
    InfoContentDialogType | undefined
  >(undefined);
  thirdPartyToken = '';
  user = signal<UserInfo | undefined>(undefined);
  game = signal<Game | undefined>(undefined);
  campaign = this.game()?.campaign;
  currentQuestionId = signal<number>(0);
  gameSubmitted = signal<boolean>(false);
  togglesPicked = signal<number>(0);
  transaction = signal<Transaction | undefined>(undefined);
  ticketCount = 1;
  stakeValue = 0;
  instance?: string;
  gameUuid?: string;
  hubGameUuid?: string;
  isFromHub = false;
  gamePlayed = false;
  numberOfQuestionsTotal = 0;
  startGameUserAction = false;

  isMobile$ = this._isMobile.asObservable();
  theme$ = this._theme.asObservable();
  isLightMode$ = this.theme$.pipe(map((theme) => !theme?.darkMode));
  hasResults$ = this._hasResults.asObservable();
  hasCurrentGameResults$ = this._hasCurrentGameResults.asObservable();
  isOpenedInfoContentDialog$ = this._isOpenedInfoContentDialog.asObservable();
  infoContentDialogType$ = this._infoContentDialogType.asObservable();
  readonly themeAnimation$ = this.theme$.pipe(
    map((theme) => theme?.backgroundAnimation),
  );
  private destroyRef = inject(DestroyRef);

  constructor(
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private router: Router,
    private userActivityService: UserActivityService,
    private readonly userActionService: UserActionService,
  ) {}

  updateIsMobile(isMobile: boolean): void {
    this._isMobile.next(isMobile);
  }

  updateHasResult(result: boolean): void {
    this._hasResults.next(result);
  }

  updateCurrentGameResult(result: boolean): void {
    this._hasCurrentGameResults.next(result);
  }

  getCurrentGameResult(): boolean {
    return this._hasCurrentGameResults.value;
  }

  updateContentDialogState(isOpened: boolean): void {
    this._isOpenedInfoContentDialog.next(isOpened);
  }

  updateInfoContentDialogType(type: InfoContentDialogType): void {
    this._infoContentDialogType.next(type);
  }

  updateCorrectScoreInStorage(
    questionUuid: string,
    competitor1Score: number,
    competitor2Score: number,
  ): void {
    const gameState = this.localStorageService.getItem<GameState[]>(GAME_STATE);
    const currentUserUuid = this.user()?.uuid;
    this.localStorageService.setItem(
      GAME_STATE,
      gameState!.map((game) => {
        if (game.uuid === this.game()!.uuid) {
          return {
            ...game,
            userUuid: currentUserUuid,
            isUserAnonymous: this.game()!.isUserAnonymous,
            questions: {
              ...game.questions,
              [questionUuid]: {
                optionUuids: [],
                competitor1Score,
                competitor2Score,
              },
            },
          };
        }
        return game;
      }),
    );
  }

  updateOptionsInStorage(questionUuid: string, pickedOptions: string[]): void {
    const gameState = this.localStorageService.getItem<GameState[]>(GAME_STATE);
    const currentUserUuid = this.user()?.uuid;
    this.localStorageService.setItem(
      GAME_STATE,
      gameState!.map((game) => {
        if (game.uuid === this.game()!.uuid) {
          return {
            ...game,
            userUuid: currentUserUuid,
            isUserAnonymous: this.game()!.isUserAnonymous,
            questions: {
              ...game.questions,
              [questionUuid]: {
                optionUuids: pickedOptions,
                competitor1Score: 0,
                competitor2Score: 0,
              },
            },
          };
        }
        return game;
      }),
    );
  }

  updateNumberSelectValueInStorage(
    questionUuid: string,
    numberSelectValue: number,
  ): void {
    const gameState = this.localStorageService.getItem<GameState[]>(GAME_STATE);
    this.localStorageService.setItem(
      GAME_STATE,
      gameState!.map((game) => {
        if (game.uuid === this.game()!.uuid) {
          return {
            ...game,
            questions: {
              ...game.questions,
              [questionUuid]: {
                optionUuids: [],
                numberSelectValue,
              },
            },
          };
        }
        return game;
      }),
    );
  }

  getLabels(): ConfigValue[] | void {
    return this._theme.value && this._theme.value.labels;
  }

  updateCurrentQuestionInStorage(questionID: number): void {
    const gameState = this.localStorageService.getItem<GameState[]>(GAME_STATE);
    const currentUserUuid = this.user()?.uuid;
    this.localStorageService.setItem(
      GAME_STATE,
      gameState!.map((game) => {
        if (game.uuid === this.game()!.uuid) {
          return {
            ...game,
            currentQuestionId: questionID,
            userUuid: currentUserUuid,
            isUserAnonymous: this.game()!.isUserAnonymous,
          };
        }
        return game;
      }),
    );
  }

  setCurrentGame(game: Game): void {
    this._theme.next(game.theme);
    this.stakeValue = game.stakeValue;
    if (game.ended || game.paused || this._hasCurrentGameResults.value) {
      this.game.set(game);
      this.numberOfQuestionsTotal = game.questions.length;
    } else {
      this.setCurrentGameFromStorage(game);
    }
  }

  updateAuthToken(): void {
    // Run every 10 minutes
    interval(10 * 60 * 1000)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        // this interval must not refresh the token trigger if the user is not active
        filter(() => !this.userActivityService.isUserInactive()),
        switchMap(() => this.fetchToken()),
      )
      .subscribe({
        next: (user) => {
          this.user.set(user);
        },
        error: () => {
          this.router.navigate(['/error']);
        },
      });
  }

  updateDataOnSubmittingPicks(): void {
    this.removeGameFromStorage();
    this.currentQuestionId.set(0);
    this.updateHasResult(true);
    this.updateCurrentGameResult(true);
    if (this.game()) {
      this.stakeValue = this.game()!.stakeValue;
    }
  }

  removeGameFromStorage(): void {
    const gameState = this.localStorageService.getItem<GameState[]>(GAME_STATE);
    if (gameState) {
      this.localStorageService.setItem(
        GAME_STATE,
        gameState.filter((game) => game.uuid !== this.game()!.uuid),
      );
    }
  }

  fetchToken(): Observable<UserInfo> {
    return this.http
      .post<UserInfo>(`${environment.backendUrl}/api/public/auth/token`, {
        token: this.thirdPartyToken,
        instance: this.instance,
      })
      .pipe(
        mergeMap((user) =>
          this.getIp().pipe(
            mergeMap((res) =>
              this.userActionService
                .sendAction(
                  UserAction.GAME_LOGIN,
                  { ip: res.ip },
                  this.game()!.uuid,
                )
                .pipe(map(() => user)),
            ),
            catchError((err) => {
              console.error('Failed to track GAME_LOGIN:', err);
              return of(user);
            }),
          ),
        ),
        catchError((err) => {
          console.error('Error fetching token:', err);
          throw err;
        }),
      );
  }

  getIp(): Observable<{ ip: string }> {
    return this.http.get<{ ip: string }>(
      environment.backendUrl + '/api/user/get-ip',
    );
  }

  private setCurrentGameFromStorage(game: Game): void {
    const gameState = this.localStorageService.getItem<GameState[]>(GAME_STATE);
    const currentUserUuid = this.user()?.uuid;
    if (!gameState) {
      // set this game to the local storage as a first game
      this.game.set(game);
      this.numberOfQuestionsTotal = game.questions.length;
      this.localStorageService.setItem(GAME_STATE, [
        {
          uuid: game.uuid,
          currentQuestionId: this.currentQuestionId(),
          questions: {},
        },
      ]);
    } else {
      // set this game to the existing game state only in the case if there is no this game there
      const currentGame = gameState.find(
        (state) => state.uuid === game.uuid,
      ) as GameState;
      if (!currentGame) {
        this.game.set(game);
        this.numberOfQuestionsTotal = game.questions.length;
        this.localStorageService.setItem(GAME_STATE, [
          // we want to store no more than 20 games in the store
          ...(gameState.length === 20 ? gameState.slice(1) : gameState),
          {
            uuid: game.uuid,
            currentQuestionId: this.currentQuestionId(),
            questions: {},
          },
        ]);
      } else {
        // prefill only for the anonymous or same user
        if (
          currentGame.isUserAnonymous ||
          currentUserUuid === currentGame.userUuid
        ) {
          // update the current question id from the local storage
          this.currentQuestionId.set(
            currentGame.questions
              ? currentGame.currentQuestionId
              : this.currentQuestionId(),
          );
          // update selected options
          this.numberOfQuestionsTotal = game.questions.length;
          this.game.set({
            ...game,
            questions: this.setPickedOptions(
              game.questions,
              currentGame.questions,
            ),
          });
        } else {
          this.game.set({
            ...game,
          });
        }
      }
    }
  }

  private setPickedOptions(
    questions: Question[],
    questionsFromStorage: Record<string, QuestionState>,
  ): Question[] {
    // this case is possible for previous version of the game state in the local storage
    if (!questionsFromStorage) return questions;
    // if there are options that were picked and set to the local storage then
    // we have to set it on loading the game
    return questions.map((question) => {
      const dataFromStorage = questionsFromStorage[question.uuid];
      if (!dataFromStorage) return question;
      if (
        question.type === QuestionType.Options ||
        question.type === QuestionType.Totals
      ) {
        return {
          ...question,
          options: !dataFromStorage
            ? question.options
            : question.options.map((option) => {
                return {
                  ...option,
                  picked: dataFromStorage.optionUuids.includes(option.uuid),
                };
              }),
        };
      }

      if (question.type === QuestionType.CorrectScore) {
        return {
          ...question,
          competitor1Score: dataFromStorage.competitor1Score,
          competitor2Score: dataFromStorage.competitor2Score,
        };
      }

      if (question.type === QuestionType.NumberSelect) {
        return {
          ...question,
          correctAnswer: dataFromStorage.correctAnswer,
        };
      }
      return question;
    });
  }
}
